<template>
    <div>
        <el-upload
                drag
                :action="fileUpUrl"
                :http-request="upFile">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>

        <el-button @click="doInitFromOrthanc">init from orthanc</el-button>
        <el-button @click="doInitFromOrthancAndImg">init from orthanc and upload image</el-button>

        <div>
            <el-input v-model="studyId"></el-input>
            <el-button @click="doInitFromOrthancByStudyId">init from orthanc by studyId</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InitData",
        data() {
            return {
                studyId: undefined
            }
        },
        computed: {
            fileUpUrl: function () {
                return this.$urlConstant.cms.articleFilePost
            }
        },
        methods:{
            upFile(req) {
                console.log(req)
                let file = req.file
                let formData = new FormData();
                formData.append('name', file.name);
                formData.append('file', file);
                this.$httpUtil.postForm(this.$urlConstant.xiangya.upload, formData, (responseData) => {
                    console.log('responseData:' + JSON.stringify(responseData));
                    this.$message({
                        message: '上传成功',
                        type: 'success'
                    });
                }, this);
            },
            doInitFromOrthanc() {
                this.$httpUtil.post(this.$urlConstant.xiangya.initOrthnac,{},(res)=>{
                    console.log(res)
                },this)
            },
            doInitFromOrthancAndImg() {
                this.$httpUtil.post(this.$urlConstant.xiangya.initOrthnacAndImg,{},(res)=>{
                    console.log(res)
                },this)
            },
            doInitFromOrthancByStudyId() {
                this.$httpUtil.post(this.$urlConstant.xiangya.initOrthnacByStudyId + this.studyId, {},(res)=>{
                    console.log(res)
                },this)
            }
        }
    }
</script>

<style scoped>

</style>
